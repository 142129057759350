<template>
  <div class="m-goal-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('goals.savingError') }}
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column is-one-third">
        <div class="container ra-form-section">
          <b-field :label="$t('goals.aspirant')" :type="aspirantUserFieldType" :message="aspirantUserValidationMessage">
            <b-select v-model="aspirantUser" :loading="loadingUsers" @change.native="resetValidation">
              <option v-for="user in users" :value="user._id" :key="user._id">
                {{ user.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="column is-one-third">
        <b-field :label="$t('goals.deadline')" :type="deadlineFieldType" :message="deadlineValidationMessage">
          <b-datepicker v-model="deadline" @change="resetValidation" />
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <b-field :label="$t('goals.primaryGoal')" :type="primaryGoalFieldType" :message="primaryGoalValidationMessage">
          <b-select v-model="primaryGoal" @change.native="resetValidation">
            <option v-for="goalType in goalTypes" :value="goalType.id" :key="goalType.id">
              {{ goalType.title }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="$t('goals.descriptionPrimaryGoal')" v-if="primaryGoal">
          <b-input
            type="textarea"
            v-model="primaryDescription"
            :placeholder="$t('goals.descriptionGoalPlaceholder')"
            :maxlength="limits.description"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-one-third">
        <b-field
          :label="$t('goals.secondaryGoal')"
          :type="secondaryGoalFieldType"
          :message="secondaryGoalValidationMessage"
        >
          <b-select v-model="secondaryGoal" @change.native="resetValidation">
            <option v-for="goalType in goalTypes" :value="goalType.id" :key="goalType.id">
              {{ goalType.title }}
            </option>
          </b-select>
        </b-field>
        <b-field :label="$t('goals.descriptionSecondaryGoal')" v-if="secondaryGoal">
          <b-input
            type="textarea"
            v-model="secondaryDescription"
            :placeholder="$t('goals.descriptionGoalPlaceholder')"
            :maxlength="limits.description"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving">{{
              $t('generic.saveButton')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import clientGoals from '../../../api/goals';
import logger from '../../../utils/logger';
import clientUsers from '../../../api/users';

export default {
  name: 'GoalsEdit',
  props: {
    goalId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      availableTraits: [3, 11, 10, 7, 12, 1, 8, 2, 5],
      users: [],
      goalTypes: [],
      loadingUsers: false,
      limits: {
        description: 1000
      },
      loading: false,
      loadingError: false,
      saving: false,
      savingError: false,
      aspirantUser: '',
      aspirantUserFieldType: '',
      aspirantUserValidationMessage: '',
      deadlineFieldType: '',
      deadlineValidationMessage: '',
      primaryGoalFieldType: '',
      primaryGoalValidationMessage: '',
      secondaryGoalFieldType: '',
      secondaryGoalValidationMessage: '',
      primaryGoal: 0,
      primaryDescription: '',
      secondaryGoal: 0,
      secondaryDescription: '',
      deadline: this.createDeadline()
    };
  },
  computed: {
    isEdit() {
      return !!this.goalId;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('goals.editGoalHeading');
      } else {
        return this.$t('goals.createGoalHeading');
      }
    }
  },
  methods: {
    createDeadline() {
      let date = new Date();
      date.setMonth(date.getMonth() + 3);
      return date;
    },
    fillGoalTypes() {
      let goalTypes = [
        {
          id: 0,
          title: this.$t('goals.noGoal')
        }
      ];

      this.availableTraits.forEach(traitNum =>
        goalTypes.push({
          userId: traitNum,
          title: this.$t('feedback.thanks[' + (traitNum - 1) + '].title')
        })
      );
      this.goalTypes = goalTypes;
    },
    // TODO: refactor, same method as in other modules (teams, feedback etc.)
    loadUsers() {
      this.loadingUsers = true;
      return clientUsers
        .list()
        .then(users => {
          let usersForSelect = [];
          users.forEach(user => {
            let company = user.company ? ' (' + user.company.name + ')' : '';
            usersForSelect.push({
              _id: user._id,
              name: user.nameLast + ' ' + user.nameFirst + company
            });
          });
          this.users = usersForSelect;
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    createDataPayload() {
      const data = {
        aspirantUser: this.aspirantUser,
        primaryGoal: this.primaryGoal,
        primaryDescription: this.primaryDescription,
        secondaryGoal: this.secondaryGoal,
        secondaryDescription: this.secondaryDescription,
        deadline: this.deadline
      };
      if (this.isEdit) {
        return { id: this.goalId, data };
      } else {
        return data;
      }
    },
    loadGoal() {
      if (!this.goalId) {
        return false;
      }
      this.loading = true;
      this.loadingError = false;
      clientGoals
        .get(this.goalId)
        .then(goal => {
          this.aspirantUser = goal.aspirantUser._id;
          this.primaryGoal = goal.primaryGoal;
          this.primaryDescription = goal.primaryDescription;
          this.secondaryGoal = goal.secondaryGoal;
          this.secondaryDescription = goal.secondaryDescription;
          this.deadline = new Date(goal.deadline);
        })
        .catch(error => {
          this.loadingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isFormValid() {
      let status = true;
      if (this.aspirantUser === '') {
        this.aspirantUserFieldType = 'is-danger';
        this.aspirantUserValidationMessage = this.$t('goals.emptyAspirantError');
        status = false;
      }
      if (this.deadline === '') {
        this.deadlineFieldType = 'is-danger';
        this.deadlineValidationMessage = this.$t('goals.deadlineError');
        status = false;
      }
      if (this.secondaryGoal > 0 && this.secondaryGoal === this.primaryGoal) {
        this.secondaryGoalFieldType = 'is-danger';
        this.secondaryGoalValidationMessage = this.$t('goals.goalDuplicityError');
        status = false;
      }
      if (this.primaryGoal === 0) {
        this.primaryGoalFieldType = 'is-danger';
        this.primaryGoalValidationMessage = this.$t('goals.primaryGoalRequiredError');
        status = false;
      }
      return status;
    },
    resetValidation() {
      this.aspirantUserFieldType = '';
      this.aspirantUserValidationMessage = '';
      this.deadlineFieldType = '';
      this.deadlineValidationMessage = '';
      this.primaryGoalFieldType = '';
      this.primaryGoalValidationMessage = '';
      this.secondaryGoalFieldType = '';
      this.secondaryGoalValidationMessage = '';
    },
    dispatch() {
      if (!this.isFormValid()) {
        return false;
      }
      this.saving = true;
      this.savingError = false;
      let payload = this.createDataPayload();
      this.$store
        .dispatch(namespaces.GOALS + (this.isEdit ? actions.EDIT_GOAL : actions.ADD_GOAL), payload)
        .then(() => {
          this.$router.replace({ name: 'goals-list' });
        })
        .catch(error => {
          this.savingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.aspirantUser = '';
      this.primaryGoal = 0;
      this.primaryDescription = '';
      this.secondaryGoal = 0;
      this.secondaryDescription = '';
      this.deadline = this.createDeadline();
      this.$router.go(-1);
    }
  },
  mounted() {
    this.fillGoalTypes();
    this.loadGoal();
    this.loadUsers();
  },
  watch: {
    goalId() {
      this.loadGoal();
    }
  }
};
</script>
